import Head from 'next/head';
import Home from "@Containers/Home";
import { nextFetch } from "@Services/api";
import { TrackerContext } from "./_app";

export async function getServerSideProps(context) {
  // Perform any data fetching or processing here using the id value
  const reqHeaders = {
    'Content-Type': 'application/json',
    caller: 'web_app',
    timestamp: Date.now().toString()
  };

  const home_data = await nextFetch.get(context, `get_page_config?page_type=home`, reqHeaders);

  return {
    props:{
      homepageData: home_data
    }
  };
}

export default function HomePage({ homepageData, ...props }) {
  return (
    <>
      <Head>
        <title>Newme Asia</title>
        <link rel="icon" href="/favicon.ico" />
      </Head>
      <Home homepageData={homepageData} TrackerContext={TrackerContext} {...props} />
    </>
  );
}
